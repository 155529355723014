<template>
    <section class="login-page pt-2 pt-sm-6 pb-12">
        <div class="page-container">

            <div class="row align-items-center">
                <div class="col-12 col-lg-4 offset-lg-1">

                    <div class="login-card-wrapper mx-lg-n3 transition-up-1">
                        <div class="login-card-bg e1" />
                        <div class="login-card-bg e2" />
                        <div class="login-card-bg e3" />
                        <div class="login-card-bg e4" />
                        <div class="login-card-bg e5" />
                        <div class="login-card-bg e6" />

                        <Card class="login-card">
                            <LoginPanel />
                        </Card>
                    </div>

                </div>

                <div class="col-12 col-lg-4 offset-lg-2 mt-6 mt-lg-0 d-none d-lg-block">
                    <div class="login-visual-wrapper transition-up-2">
                        <img class="block-image scheme-light-block" src="/images/login-visual-light.png" />
                        <img class="block-image scheme-dark-block" src="/images/login-visual-dark.png" />
                    </div>
                </div>
            </div>

        </div>
    </section>
</template>


<script>
    import Card      from '~/components/cards/Card.vue';
    import LoginPanel  from '~/components/panels/LoginPanel.vue';

    export default {
        name: 'LoginPage',

        components: {
            Card,
            LoginPanel
        },

        head () {
            return {
                title: `${this.$t('login.title')} | CASES`,
                bodyAttrs: {
                    class: 'gray-page'
                },
            };
        },

        async asyncData({ app, route, $auth, redirect, $appStore }) {
            if ($auth.user) {
                let next = (route.query && route.query.next) || '/';

                return redirect(app.localePath(next));
            }

            $appStore.$patch({activeApp: 'profile'})
        }
    }
</script>


<style lang="postcss" scoped>
    .login-page {
        display:     flex;
        flex-flow:   row nowrap;
        align-items: center;

        overflow: hidden;
        min-height: 100%;
    }

    .login-card-wrapper,
    .login-visual-wrapper {
        margin-right: auto;
        margin-left:  auto;

        @media screen and (max-width: 991px) {
            max-width: 376px;
        }
    }

    .login-card-wrapper {
        position: relative;
    }

    .login-card-bg {
        position: absolute;
        z-index:  1;
    }

    .e1 {
        background: #FF3399;
        filter:     blur(40px);

        width:  150px;
        height: 150px;

        right:  33px;
        bottom: 42px;
    }

    .e2 {
        background: #9B74ED;
        filter:     blur(60px);

        width:  80px;
        height: 80px;

        top:   90px;
        right: -18px;
    }

    .e3 {
        background: #0097C0;
        filter:     blur(40px);

        width:  90px;
        height: 90px;

        top:   10px;
        right: 12px;
    }

    .e4 {
        background: #42B8A4;
        filter:     blur(40px);

        width:  80px;
        height: 80px;

        top:  80px;
        left: 14px;
    }

    .e5 {
        background: #FFCE0A;
        filter:     blur(50px);

        width:  80px;
        height: 80px;

        top:   0;
        right: 122px;
    }

    .e6 {
        background: #FFCE0A;
        filter:     blur(40px);

        width:  120px;
        height: 120px;

        top:  165px;
        left: 0;
    }
    

    .login-card {
        position: relative;
        z-index:  2;
    }
</style>
