<template>
    <div class="form-group"
        :class="{
            disabled,
            error
        }"
    >

        <div
            class="text-input"
            :class="{focus: focused}"
            @click="focus"
        >
            <slot />

            <input
                :type="inputType"
                :name="name"
                :disabled="disabled"
                :placeholder="placeholder"
                :list="list"
                :min="min"
                :max="max"
                :step="step"
                :id="inputId"
                :autocomplete="autocomplete"
                :autocorrect="autocorrect"
                :pattern="pattern"

                v-bind:value="value"
                @input="$emit('input', $event.target.value)"
                @focus="onFocus"
                @blur="focused = false;"

                ref="input"
            >
        </div>

        <transition name="smooth">
            <div
                class="feedback color-red f-small"
                v-if="error"
            >
                <div class="mt-1">{{error}}</div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    props: [
        'value', 'type', 'name', 'disabled', 'error', 'placeholder', 'list',
        'min', 'max', 'inputId', 'autocomplete', 'step', 'autocorrect', 'pattern'
    ],

    data () {
        return {
            focused: false,
            inputType: 'text'
        }
    },

    computed: {
        // filled () {
        //     return (this.value && this.value.trim().length > 0) || this.focused;
        // }
    },

    mounted () {
        this.inputType = this.type || 'text';
    },

    methods: {
        focus () {
            this.$refs.input.focus()
        },

        onFocus () {
            this.focused = true;
            this.$emit('focus');
        }
    }
}
</script>
